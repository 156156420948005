









































































































import { Component, Vue } from 'vue-property-decorator'

import ResponseDialog from '@/components/ResponseDialog.vue'
import DataIterator from '@/components/DataIterator.vue'

import { copyToClipboard } from '@/functions/events'
@Component({
    components: {
      ResponseDialog,
      DataIterator
    }
})
export default class RegisterUser extends Vue {
  roles = [
    { displayName: 'Admin',     value: 'Admin',     suffix: '@energievolutie.nl' },
    { displayName: 'Moderator', value: 'Moderator', suffix: '@idea-nederland.nl' },
    { displayName: 'Beheerder', value: 'SuperUser' },
    { displayName: 'Gebruiker', value: 'User' }
  ]

  days = 7
  emailPrefix = ''
  role = this.roles[3]

  response: {[key: string]: any} = {
    email: '',
    url: '',
    expires: new Date().toLocaleString()
  }

  get responseIterable(): Array<any> {
    const keys = Object.keys(this.response)

    return keys.map((key: string) => { 
      return { label: key, value: this.response[key], copy: true} 
      })
  }

  get recentRegistrations() {
    return this.$store.getters['admin/registrations']
  }

  get email() : string {
    return this.emailPrefix + (this.role.suffix ?? '')
  }

  copyToClipboard = copyToClipboard
  
  register() : void {
    const obj = {
      email: this.email,
      role: this.role.value
    }

    this.$store.dispatch('admin/register', obj).then(_ => {
      this.response = _.data
      const dialog : any = this.$refs.dialog
      dialog.toggle();
    }).catch(_ => console.log(_))
  }
}
